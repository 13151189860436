<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img
          class="img-fluid"
          style="width:100%; max-width: 180px;"
          src="@/assets/images/aqaratic-logo2.svg"
          alt="image"
        >
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{ $t('Forgot Password') }} 🔒
          </b-card-title>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <div>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('labels.password')"
                  vid="password"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('labels.password')"
                  >
                    <b-form-input
                      v-model="password"
                      type="password"
                      :placeholder="$t('labels.password')"
                      autocomplete="new-password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>

              <div>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('labels.new_password')"
                  vid="confirm_password"
                  rules="password"
                >
                  <b-form-group
                    :label="$t('labels.new_password')"
                  >
                    <b-form-input
                      v-model="new_password"
                      type="password"
                      :placeholder="$t('labels.new_password')"
                      autocomplete="new-password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>

              <div>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('labels.confirm_password')"
                  vid="confirm_password"
                  rules="required|min:6|confirmed:new_password"
                >
                  <b-form-group
                    :label="$t('labels.confirm_password')"
                  >
                    <b-form-input
                      v-model="password_confirmation"
                      type="password"
                      :placeholder="$t('labels.confirm_password')"
                      autocomplete="new-password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('reset password') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              {{ $t('Back to login') }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BCardTitle, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      password: '',
      new_password: '',
      password_confirmation: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'This is for UI purpose only.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
